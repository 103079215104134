<template>
  <div class="row spacing-vertical">
    <div class="column single">
      <h1>Kontakt oss</h1>
      <p style="text-align:center">Kolbensrødveien 21 - 3294 Stavern</p>
      <p style="text-align:center">+47 917 86 211</p>
      <p style="text-align:center">cbh@ingcbh.no</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactUs"
});
</script>
