
import { ProductCategory } from "../types";
import { defineComponent } from "vue";
import ProductCategoryService from "@/services/ProductCategoryService";

export default defineComponent({
  props: {
    categoryId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      category: {} as ProductCategory
    };
  },
  name: "Products",
  created() {
    this.category = ProductCategoryService.getProductCategory(this.categoryId) as ProductCategory;
    this.$watch(() => this.$route.params,(toParams: any, previousParams: any) => {
      this.category = ProductCategoryService.getProductCategory(toParams.categoryId) as ProductCategory;
    })
  }
});
