import { ProductCategory } from "../types";

export default {
  getProductCategory(categoryId: number) {
    if (categoryId == 1) {
      const category = {id: 1, title: 'Vanningsmaskiner', description: '<p>Ing. Carl B. Hagen AS er eneimportør av vanningsutstyr fra Italienske RM.</p><p>RM er en av verdens ledende produsenter av vanningsmaskiner, og har produsert vanningsutstyr siden 1952.</p><p>RM leverer alle typer maskiner, fra vanning av grøntområder, maskiner for landbruksvanning, vanningsbommer, og motorpumper.</p><p>Ta kontakt for nærmere informasjon og pristilbud.</p>'};
      return category;
    }
    if (categoryId == 2) {
      const category = {id: 2, title: 'Hurtigkoblinger', description: '<p>Vi forhandler og lagerfører en rekke forskjellige hurtigkoblinger som Bauer, Perrot, Geka, Farrow, Alfa Laval, Storz, Guillemin, Camlock etc.</p><p>Ta kontakt for nærmere informasjon og pristilbud.</p>'};
      return category;
    }
    if (categoryId == 3) {
      const category = {id: 3, title: 'Slanger', description: '<p>Vi forhandler og  lagerfører en rekke forskjellige slanger for bruk til bl.a. vanning og gylle.</p><p>Ta kontakt for nærmere informasjon og pristilbud.</p>'};
      return category;
    }
  }
}