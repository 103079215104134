<template>
    <div class="wrapper">
    <div class="header">
      <div class="company">
        <div class="logo">
          <img src="./assets/logo.png">
        </div>
        <div class="intro">
          <a>- din spesialist på vanningsvogner</a>
        </div>
      </div>
      <div class="navbar">
        <router-link to="/">Hjem</router-link>
        <div class="dropdown">
          <button class="dropbtn">
            Produkter<i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <router-link :to="{ name: 'products', params: { categoryId: 1 } }">
              Vanningsmaskiner
            </router-link>
            <router-link :to="{ name: 'products', params: { categoryId: 2 } }">
              Hurtigkoblinger
            </router-link>
            <router-link :to="{ name: 'products', params: { categoryId: 3 } }">
              Slanger
            </router-link>
          </div>
        </div>
        <router-link to="/omoss">Om oss</router-link>
        <router-link to="/kontaktoss">Kontakt oss</router-link>
      </div>
    </div>
    <div class="content greenlight">
      <router-view />
    </div>
    <div class="footer">
      <div class="row">
        <div class="column">
          <h3>Om oss</h3>
          <hr />
          <p>
            Ing. Carl B. Hagen AS har siden 2010 importert og forhandlet
            vanningsutstyr til landbruket, samt produkter til bygg, industri og
            infrastruktur.
          </p>
          <p>
            Vi har bred kompetanse og lang erfaring innen segmentet vi driver
            med, og bistår deg gjerne med råd og veiledning på produkter samt
            store prosjekter dere måtte trenge bistand til.
          </p>
          <p>
            Hos oss får du kvalitetsprodukter til konkurransedyktige priser i
            tillegg til opplæring og personlig oppfølging.
          </p>
        </div>
        <div class="column">
          <h3>Kontakt oss</h3>
          <hr />
          <p>Kolbensrødveien 21 - 3294 Stavern</p>
          <p>+47 917 86 211</p>
          <p>cbh@ingcbh.no</p>
        </div>
      </div>
      <div class="row">
        <div class="column single ">
          <span>www.ingcbh.no &copy; 2021</span>
        </div>
      </div>
    </div>
  </div>
</template>
