import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import ContactUs from "../views/ContactUs.vue";
import AboutUs from "../views/AboutUs.vue";
import Products from "../views/Products.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: '/produkter/:categoryId',
    props: true,
    name: 'products',
    component: Products
  },
  {
    path: "/omoss",
    name: "AboutUs",
    component: AboutUs,
    
  },
  {
    path: "/kontaktoss",
    name: "ContactUs",
    component: ContactUs
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
