<template>
  <div class="row spacing-vertical">
    <div class="column single">
      <h1>Velkommen til Ing. Carl B. Hagen AS</h1>
      <p>Ing. Carl B. Hagen AS er leverandør av VMT-produkter for bygg, industri, infrastruktur og landbruk.</p>
      <p>Vi har et stort vareutvalg fra ledende leverandører, og vår hjemmeside viser et utvalg av de produkter vi forhandler.</p>
      <p>Ta kontakt med oss slik at vi kan hjelpe deg med det du søker.</p>
    </div>
    <div class="column center">
      <router-link :to="{ name: 'products', params: { categoryId: 1 } }">
        <button class="categoryButton vanningsmaskiner"></button>
      </router-link>
    </div>
    <div class="column center">
      <router-link :to="{ name: 'products', params: { categoryId: 2 } }">
        <button class="categoryButton hurtigkoblinger"></button>
      </router-link>
    </div>
  </div>
  <div class="row spacing-vertical">
    <div class="column green">
      <h1>Tidspunkt for vanning</h1>
      <p>
        For å få en god utnyttelse av vanningsanlegget forutsettes det at en
        kjenner jorda si. En må vite hvor faren for tørke er størst, hvilke
        kulturer som har størst vanningsbehov, og på hvilket utviklingsstadium
        behovet er størst. Dersom en har et stort areal, er det viktig å starte
        vanningsanlegget i tide, slik at en rekker over hele arealet som skal
        vannes, før det er for seint.
      </p>
      <h3>Vanning om natta</h3>
      <p>
        Plantenes bladoverflate er fuktigere om natta enn om dagen. Dersom
        tilgangen på vann er begrenset, er det bedre vannøkonomi å vanne om
        natta, når fordampningen er minst. Dessuten vil en, ved å vanne om
        dagen, forlenge den perioden av døgnet da bladene er fuktige, noe som
        kan øke faren for utvikling av enkelte soppsjukdommer.
      </p>
      <h3>Vanning til oppal</h3>
      <p>
        Vanning om natta eller tidlig om morgenen bør også gjøres på planter i
        oppal. Til oppalsplanter bør en dessuten sørge for undervanning framfor
        vanning ovenfra.
      </p>
      <h3>Tensiometermålinger eller vannregnskap</h3>
      <div>
        <p>Tidspunkt for vanning kan bestemmes etter to metoder:</p>
        <ul>
          <p>
            Bestemme tidspunktet ut fra tensiometermålinger. Et tensiometer
            måler jordas sugeevne (tension). Denne metoden passer best på
            spesielle kulturer og på lettere jordarter. Vanning bør ta til når
            undertrykket er på 0,4-0,5 bar. Tensiometeret må stå i bakken
            permanent fra våren av.
          </p>
          <p>
            Bestemme tidspunktet gjennom et vannregnskap som beregner
            underskuddet på vann. Her kreves det opplysninger om fordampning og
            nedbør.
          </p>
        </ul>
      </div>
      <h3>Mengde</h3>
      <p>
        Det kan vannes cirka 30-35 mm per gang. På stive, moldholdige jordarter
        og på kulturer med dypt rotsystem kan det vannes mer. I kulturer med
        grunnere rotsystem og på lettere jordarter er 20 mm per gang
        tilstrekkelig, men det må da vannes oftere.
      </p>
      <p>
        For små vannmengder (10-15 mm) øker som regel vannbehovet ved at
        fordampningen fra jordoverflata totalt sett blir større. For store
        vannmengder øker faren for utvasking av næringsstoff.
      </p>
    </div>
    <div class="column green">
      <h1>Valg av vanningsutstyr</h1>
      <p>
        Vanning med stort trykk og stor dyse på leirjord kan føre til at
        jordpartiklene klistres så tett inntil hverandre at det dannes skorper i
        overflata ved opptørking. Jordarter som silt og finsand har såpass god
        kapillær ledningsevne at kulturer på slik jord bare unntaksvis trenger
        vanningsanlegg. Kostnadene ved å etablere nytt vanningsanlegg eller
        ruste opp gammelt utstyr er såpass store at en bør gjøre grundige
        økonomiske beregninger på forhånd. Tilgangen på vann har stor betydning
        for valg av anlegg. Arrondering har stor betydning for valg av
        anleggstype. Ligger jordene spredd, vil etablering av vanningsanlegg bli
        svært kostbart, om det da ikke er muligheter for fellesanlegg med andre
        gardbrukere.
      </p>
      <h3>Dryppvanning</h3>
      <p>
        Dryppvanningssystemer er vannbesparende, men relativt kostbart. Ulike
        typer dryppvanning har store fordeler i enkelte grønnsakkulturer, frukt-
        og bærproduksjon samt i bladurter. Med dryppvanning vanner en bare der
        kulturplantene er, og en reduserer derfor spiremulighetene for ugrasfrø
        mellom planteradene og i planteraden. Videre er det svært viktig at
        bladplanter, som urter og salat, ikke får jordsprut, da dette kan
        redusere kvaliteten på produktene betraktelig. Utlegging av dryppslange
        under plast er vanlig i for eksempel jordbær.
      </p>
      <h3>Gjødsling via vanning</h3>
      <p>
        Teoretisk kan en gjødsle via dryppslangen, også i økologisk landbruk,
        men det er da stor fare for at dyser og hull tettes av fraksjoner av
        organiske gjødselmidler. Gjødselløsningen må være skikkelig fortynnet og
        godt filtrert før en prøver dette.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
});
</script>
