<template>
  <div class="row spacing-vertical">
    <div class="column single">
      <h1>Om oss</h1>
      <p>
        Ing. Carl B. Hagen AS har siden 2010 importert og forhandlet
        vanningsutstyr til landbruket, samt produkter til bygg, industri og
        infrastruktur.
      </p>
      <p>
        Vi har bred kompetanse og lang erfaring innen segmentet vi driver med,
        og bistår deg gjerne med råd og veiledning på produkter samt små og
        store prosjekter dere måtte trenge bistand til.
      </p>
      <p>
        Hos oss får du kvalitetsprodukter til konkurransedyktige priser i
        tillegg til opplæring og personlig oppfølging.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutUs",
});
</script>
